<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="filters.name"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索產品名稱"
          />
        </div>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="">
        <b-button
          @click="showSetPromotionModal()"
          class="btn btn-sm btn-primary btn--h46px rounded-10px px-4"
        >
          將產品加入促銷活動中
        </b-button>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
    </b-row>

    <table class="table table-hover bg-white shadow-sm">
      <thead class="bg-brown--light">
        <tr>
          <th></th>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="text-center align-self-center"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>

      <template v-if="isLoading">
        <tr v-for="n of 10" :key="n" class="animate-pulse">
          <td v-for="header of 13" :key="header">
            <div
              class="align-middle text-center text-nowrap py-3 rounded-pill"
              style="background-color: rgb(229, 231, 235)"
            >
            </div>
          </td>
        </tr>
      </template>

      <tr v-else-if="!paginatedData.length">
        <td
          :colspan="9"
          class="align-middle py-4 text-left text-nowrap"
        >
          沒有找到任何結果
        </td>
      </tr>

      <draggable
        v-else tag="tbody"
        v-model="draggableData"
        @end="handleDragEnd"
      >
        <tr
          v-for="(item, idx) in paginatedData"
          :key="item.id + '-' + idx"
          :data-id="item.id"
          :class="{'grab': !dragging && !disabledDraggable}"
          :style="!item.active ? 'background: rgba(100, 100, 100, 0.2);' : ''"
        >
          <td class="align-middle text-center text-nowrap">
            <div class="d-flex align-items-center">
              <IconSortingOrder :class="disabledDraggable ? 'opacity-25': 'opacity-100'" style="height: 18px; width:18px; cursor: pointer;" />
            </div>
          </td>
          <td class="align-middle text-center text-nowrap">{{(item.posId || '-')}}</td>
          <td class="align-middle text-center">
            <img
              width="75"
              class="img-fluid rounded-sm shadow-sm"
              v-if="item.imageUrl"
              v-bind:src="item.imageUrl[0]"
            />
          </td>
          <td class="align-middle text-center">{{(item.nameZhHk || '-')}}</td>
          <td class="align-middle text-center">{{(item.nameZhCn || '-')}}</td>
          <td class="align-middle text-center">
            <div class="text-truncate-3" v-html="(item.descriptionZhHk || '-')"></div>
          </td>
          <td class="align-middle text-center">
            <div class="text-truncate-3" v-html="(item.descriptionZhCn || '-')"></div>
          </td>
          <td class="align-middle text-center text-nowrap">{{(item.price || '-')}}</td>
          <td class="align-middle text-center text-nowrap">{{!item.discountPercentage ? item.price : (item.price / 100 * ( 100 - item.discountPercentage )).toFixed(2)}}</td>
          <td class="align-middle text-center text-nowrap">{{(item.inventory || '-')}}</td>
          <td class="align-middle text-center text-nowrap">{{item.createdDate | formatDate}}</td>
          <td class="align-middle text-center text-nowrap">
            <template>
              <div v-if="item.active == true" class="text-success">啟用</div>
              <div v-else-if="item.active == false" class="text-danger">停用</div>
            </template>
          </td>

          <td class="align-middle text-center text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="text-white text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon
                  icon="three-dots"
                  font-scale="1.5"
                  class="text-dark"
                ></b-icon>
              </template>
              <b-dropdown-item v-on:click="deletePromotionalProduct(item.id)"
                >刪除</b-dropdown-item
              >
            </b-dropdown>
          </td>
        </tr>
      </draggable>
    </table>

    <Pagination
      v-if="paginatedData.length && !isLoading"
      v-model="currentPage"
      :list-data="filterProduct"
      :limit="limit"
    />
    <b-modal
      ref="setPromotionModal"
      centered
      size="lg"
      no-close-on-backdrop
      hide-header
      hide-footer
    >
      <b-form class="px-3 py-4">
        <h5 class="text-brown mb-4">將產品加入促銷活動中</h5>
        <multiselect
          @select="noIdsSelected = !productIds.length"
          v-model="productIds"
          placeholder="選擇"
          track-by="id"
          :options="productsList"
          :multiple="true"
          :custom-label="nameFormatter"
          :close-on-select="false"
        >
          <template #option="{ option }">
            <div class="d-flex align-items-center" style="gap: 8px">
              <div v-if="!!option.imageUrl">
                <img :src="option.imageUrl[0]" width="30" height="30" />
              </div>
              <div>
                <span>{{ option.posId }}</span
                ><span> - {{ option.nameZhHk }}</span>
              </div>
            </div>
          </template>
        </multiselect>
        <div class="d-flex mt-5 align-items-center justify-content-end">
          <b-button
            @click="hideSetPromotionModal()"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
            >取消</b-button
          >
          <b-button
      @click="setPromotionalProduct()"
      type="button"
      variant="primary"
      class="rounded-10px px-3 px-lg-5"
      >保存</b-button>
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
  getPromotionalProducts,
  getAllProducts,
  removePromotionalProducts,
  setMultiPromotionalProducts,
  updateProductPromotionSortingOrder
} from '@/services/product.service'
import Pagination from '../../components/Pagination.vue'
import Multiselect from 'vue-multiselect'
import { filterByDate } from '../../utils'
import IconSortingOrder from '../../components/icons/IconSortingOrder.vue'
import draggable from 'vuedraggable'

export default {
  name: 'Product',

  data: () => ({
    filters: {
      name: '',
      start: '',
      end: '',
      size: 10,
      page: 0
    },
    products: [],
    currentPage: 1,
    limit: 10,
    productsList: [],
    selectedProId: null,
    productIds: [],
    draggableData: [],
    headers: [
      { text: '產品編號 ', value: 'posId' },
      { text: '預覽產品相片', value: 'imageUrl' },
      { text: '產品名稱', value: 'nameZhHk' },
      { text: '产品名称(简体版本)', value: 'nameZhCn' },
      { text: '產品描述', value: 'description' },
      { text: '产品描述 (简体版本)', value: 'descriptionCn' },
      { text: '價格 (HKD)', value: 'price' },
      { text: '折後價 (HKD)', value: 'discountPrice' },
      { text: '庫存', value: 'inventory' },
      { text: '創建日期', value: 'createDate' },
      { text: '狀態', value: 'status' },
      { text: '操作', value: 'action' }
    ],
    isLoading: false
  }),
  components: {
    Pagination,
    Multiselect,
    IconSortingOrder,
    draggable
  },
  mounted () {
    this.getAllPromotionalProducts()
  },
  watch: {
    filterProduct () {
      this.currentPage = 1
    },
    paginatedData (newData) {
      this.draggableData = [...newData]
    }
  },
  computed: {
    filterProduct () {
      return this.products.filter((item) =>
        this.filters.name.length ? item.nameZhHk.toLowerCase()
          .replace(/\s+/g, '')
          .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item.nameZhCn.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item.posId.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, ''))
          : true
      ).filter((item) =>
        filterByDate(
          item.createdDate,
          this.filters.start,
          this.filters.end
        )
      )
    },
    paginatedData () {
      return this.filterProduct.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  methods: {
    showSetPromotionModal () {
      this.$refs.setPromotionModal.show()
    },
    hideSetPromotionModal () {
      this.$refs.setPromotionModal.hide()
      this.productIds = []
    },
    async getAllPromotionalProducts () {
      this.isLoading = true
      const response = await getPromotionalProducts()
      this.products = response.data
      const productIds = this.products.map((f) => f.id)
      this.isLoading = false
      await getAllProducts().then(
        res => {
          this.productsList = res.data.data.filter((s) => !productIds.includes(s.id))
        }
      )
    },
    async handleDragEnd () {
      this.isLoading = true
      const sortingOrders = this.draggableData.map(item => item.promotionSortingOrder).sort((c, n) => c - n)
      const sortedData = this.draggableData.map((item, idx) =>
        ({ productId: item?.id, sortingOrder: sortingOrders[idx] }))
      await updateProductPromotionSortingOrder(sortedData)
      await this.getAllPromotionalProducts()
    },
    // disabledDraggable  () {
    //   return this.filters.name || this.filters.start || this.filters.end
    // },
    async setPromotionalProduct () {
      const productMapping = this.productIds.map((f) => f.id)
      await setMultiPromotionalProducts(productMapping).then(
        (res) => {
          if (res.data.success) {
            this.makeToast('success', '產品推廣', '產品已設定促銷')
            this.getAllPromotionalProducts()
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.hideSetPromotionModal()
    },
    async deletePromotionalProduct (id) {
      await removePromotionalProducts(id).then(
        (res) => {
          if (res.success) {
            this.makeToast('success', '產品推廣', '產品已從促銷中刪除')
            this.getAllPromotionalProducts()
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.hideSetPromotionModal()
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    nameFormatter (option) {
      return `${option.posId} - ${option.nameZhHk}`
    }
  }
}
</script>
